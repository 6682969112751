import React from 'react';
import { FormattedMessage } from 'react-intl';

import user1 from '../../images/tweet/user1.png';
import user2 from '../../images/tweet/user2.png';
import user3 from '../../images/tweet/user3.png';
import user4 from '../../images/tweet/user4.png';

const images = [user1, user2, user3, user4];

const TwitterFans = () => (
  <>
    <h2>
      <FormattedMessage id="twitter:title" />
    </h2>

    <p><FormattedMessage id="twitter:subtitle" /></p>
    <div className="columns">
      {images.map((image, index) => (
        <div key={image} className="column">
          <div className="card">
            <img src={image} alt={`user${index}`} />
          </div>
        </div>
      ))}
    </div>
  </>
);

export default TwitterFans;
