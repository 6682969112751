import React from 'react';
import { FormattedMessage } from 'react-intl';

const TrustedUser = () => (
  <div className="trusted-block ">
    <div className="container">
      <h3 style={{ marginBottom: '50px' }}><FormattedMessage id="trusted:title" /></h3>
      <div className="columns">
        {[1, 2, 3].map(num => (
          <div className="column" key={num}>
            <div className="quote-box">
              <div className="quote-text">
                <i className="fa fa-quote-left" />
                <div className="quote-content">
                  <FormattedMessage id={`trusted:${num}:content`} />
                </div>
              </div>
              <div className="quote-author">
                -
                {' '}
                <span>
                  <FormattedMessage id={`trusted:${num}:author`} />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default TrustedUser;
