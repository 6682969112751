import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-responsive-modal';
import Video from './Video';
import './DemoList.css';
import { macOrWindows, isChina } from '../../utils';

const system = macOrWindows();
const fromChina = isChina();

const allList = {
  mac: {
    shortcut: 'https://www.youtube.com/embed/tDw9j9Uv_q4',
    customized: 'https://www.youtube.com/embed/ki1D4Ee2YHQ',
    highlight: 'https://www.youtube.com/embed/lhFigA3N6ec',
    markdown: 'https://www.youtube.com/embed/YIxNQM17QOY',
    resize: 'https://www.youtube.com/embed/_1TJhgp5M5A',
  },
  win: {
    shortcut: 'https://www.youtube.com/embed/MdTG5isse7s',
    customized: 'https://www.youtube.com/embed/ysii6nl_JIE',
    highlight: 'https://www.youtube.com/embed/KCWkYQunSD8',
    markdown: 'https://www.youtube.com/embed/CPQ7kZ8Emew',
    resize: 'https://www.youtube.com/embed/X64xYUi-rus',
  },
};

const chinaList = {
  mac: {
    shortcut: '/video/mac/shortcut',
    customized: '/video/mac/customized',
    highlight: '/video/mac/highlight_code',
    markdown: '/video/mac/markdown',
    resize: '/video/mac/resize_image',
  },
  win: {
    shortcut: '/video/win/shortcut',
    customized: '/video/win/customized',
    highlight: '/video/win/highlight_code',
    markdown: '/video/win/markdown',
    resize: '/video/win/resize_image',
  },
};

const videoList = fromChina ? chinaList[system] : allList[system];

const List = ({ text, onClick }) => (
  <li>
    <button type="button" className="button is-text has-text-link is-size-5" onClick={onClick}>
      <i className="fab fa-youtube" />
      <FormattedMessage id={text} />
    </button>
  </li>
);

class Usage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      currentVideo: null,
    };
  }

  showVideo = (currentVideo) => {
    this.setState({ currentVideo, showModal: true });
  }

  closeVideo = () => {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal, currentVideo } = this.state;
    return (
      <div className="demo-wrapepr">
        <div className="container text-width">
          <h2><FormattedMessage id="demo.video" /></h2>
          <ul className="demo-video-list">
            <List text="demo:markdown" onClick={() => { this.showVideo(videoList.markdown); }} />
            <List text="demo:shortcut" onClick={() => { this.showVideo(videoList.shortcut); }} />
            <List text="demo:resize" onClick={() => { this.showVideo(videoList.resize); }} />
            <List text="demo:highlight" onClick={() => { this.showVideo(videoList.highlight); }} />
            <List text="demo:customized" onClick={() => { this.showVideo(videoList.customized); }} />
          </ul>
        </div>
        <Modal
          open={showModal}
          onClose={this.closeVideo}
          center
          styles={{ modal: { height: '460px', width: '100%', textAlign: 'center' } }}
        >
          {fromChina
            ? <Video link={currentVideo} />
            : (
              <div className="demo-video">
                <iframe
                  title="EverTool demo"
                  width="100%"
                  height="394"
                  src={currentVideo}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              </div>
            )
          }
        </Modal>

      </div>
    );
  }
}

export default Usage;
