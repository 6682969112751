import React from 'react';
import Img from 'gatsby-image';
import './Feature.css';

const Feature = ({ img, text, onClick }) => (
  <div className="column">
    <div className="hero">
      <div className="hero-body" style={{ padding: '1.5rem' }}>
        <div
          className="screenshot"
          onClick={onClick}
          onKeyDown={onClick}
          role="button"
          tabIndex="0"
        >
          <Img fluid={img.childImageSharp.fluid} />
        </div>
        <p className="is-size-5">
          {text}
        </p>
      </div>
    </div>
  </div>
);

export default Feature;
