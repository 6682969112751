import React from 'react';
import { withPrefix } from 'gatsby';

const Video = ({ link }) => (
  <video style={{ height: '100%' }} controls preload autoPlay>
    <source src={withPrefix(`${link}.mp4`)} media="only screen and (min-device-width: 568px)" />
    <source src={withPrefix(`${link}.iphone5.mp4`)} media="only screen and (max-device-width: 568px)" />
    <source src={withPrefix(`${link}.webmhd.webm`)} />
  </video>
);

export default Video;
