import React from 'react';
import { graphql } from 'gatsby';
import { IntlProvider } from 'react-intl';

import Layout from '../components/layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Home from '../components/Home';
import getMessages from '../locales/getMessages';


const messages = getMessages();

const meta = {
  en: {
    title: 'Markdown and code highlight for Evernote - EverTool',
    subtitle: 'Make Markdown, code highlight and easily switching text style available in Evernote',
  },
  'zh-tw': {
    title: 'Evernote Markdown 及程式碼美化助手 - EverTool',
    subtitle: '輕鬆在 Evernote 使用 Markdown, 程式碼美化以及快速轉換文字樣式',
  },
  'zh-cn': {
    title: '印象笔记 Markdown 及程式码美化应用 - EverTool',
    subtitle: '轻松在印象笔记使用 Markdown, 程式码美化以及快速转换文字样式',
  },
  ja: {
    title: 'Evernote の Markdown とコードハイライト - EverTool',
    subtitle: 'EvernoteのでMarkdownを、コードのハイライトと簡単に切り替えるテキストスタイルを使用できるようにします',
  },
};

const IndexRoute = ({ pageContext, data }) => {
  const { url } = data.site.siteMetadata;
  const { locale, featureText } = pageContext;

  const features = {
    en: {
      background: data.enBackground,
      code: data.enCode,
      font: data.enFont,
      md: data.enMD,
      image: data.enImage,
      table: data.enTable,
      url: data.enUrl,
    },
    'zh-tw': {
      background: data.zhTWBackground,
      code: data.zhTWCode,
      font: data.zhTWFont,
      md: data.zhTWMD,
      image: data.zhTWImage,
      table: data.zhTWTable,
      url: data.zhTWUrl,
    },
    'zh-cn': {
      background: data.zhCNBackground,
      code: data.zhCNCode,
      font: data.zhCNFont,
      md: data.zhCNMD,
      image: data.zhCNImage,
      table: data.zhCNTable,
      url: data.zhCNUrl,
    },
    ja: {
      background: data.zhTWBackground,
      code: data.zhTWCode,
      font: data.zhTWFont,
      md: data.zhTWMD,
      image: data.zhTWImage,
      table: data.zhTWTable,
      url: data.zhTWUrl,
    },
  };

  const screenshot = {
    mac: {
      preview1: data.macPreview1,
      preview2: data.macPreview2,
    },
    win: {
      preview1: data.winPreview1,
      preview2: data.winPreview2,
    },
  };

  return (
    <Layout
      pageContext={pageContext}
      seo={{
        title: meta[locale].title,
        subtitle: meta[locale].subtitle,
        hreflangs: pageContext.hreflangs,
        url,
        locale,
      }}
    >
      <IntlProvider locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
        <div>
          <Header />
          <Home
            featureText={featureText}
            locale={locale}
            homeImg={data.homeImg}
            featureImg={features}
            screenshotImg={screenshot}
          />
        </div>
        <Footer />
      </IntlProvider>
    </Layout>
  );
};

export default IndexRoute;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        url
      }
    }
    homeImg: file(relativePath: { eq: "home-screenshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    enBackground: file(relativePath: { eq: "features/en/Background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    enCode: file(relativePath: { eq: "features/en/Code Highlight.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    enFont: file(relativePath: { eq: "features/en/Font management.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    enMD: file(relativePath: { eq: "features/en/Markdown.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    enImage: file(relativePath: { eq: "features/en/Resize image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    enTable: file(relativePath: { eq: "features/en/Table.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    enUrl: file(relativePath: { eq: "features/en/Url.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhTWBackground: file(relativePath: { eq: "features/zh-tw/Background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhTWCode: file(relativePath: { eq: "features/zh-tw/Code Highlight.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhTWFont: file(relativePath: { eq: "features/zh-tw/Font management.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhTWMD: file(relativePath: { eq: "features/zh-tw/Markdown.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhTWImage: file(relativePath: { eq: "features/zh-tw/Resize image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhTWTable: file(relativePath: { eq: "features/zh-tw/Table.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhTWUrl: file(relativePath: { eq: "features/zh-tw/Url.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhCNBackground: file(relativePath: { eq: "features/zh-cn/Background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhCNCode: file(relativePath: { eq: "features/zh-cn/Code Highlight.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhCNFont: file(relativePath: { eq: "features/zh-cn/Font management.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhCNMD: file(relativePath: { eq: "features/zh-cn/Markdown.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhCNImage: file(relativePath: { eq: "features/zh-cn/Resize image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhCNTable: file(relativePath: { eq: "features/zh-cn/Table.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zhCNUrl: file(relativePath: { eq: "features/zh-cn/Url.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    macPreview1: file(relativePath: { eq: "features/mac/preview1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    macPreview2: file(relativePath: { eq: "features/mac/preview2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    winPreview1: file(relativePath: { eq: "features/win/preview1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    winPreview2: file(relativePath: { eq: "features/win/preview2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
