import React, { Component } from 'react';
import Lightbox from 'react-images';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-responsive-modal';
import Img from 'gatsby-image';
import TextLoop from 'react-text-loop';

// Components
import Feature from './Feature';
import DemoList from './DemoList';
import DownloadBadge from '../DownloadBadge';
import Video from './Video';
import TrustedUser from './TrustedUser';
import TwitterFans from './TwitterFans';
import { macOrWindows, isChina } from '../../utils';

// Images
import './index.scss';

const fromChina = isChina();

class Index extends Component {
  constructor(props) {
    super(props);
    const { featureText, locale } = props;
    const system = macOrWindows();
    const features = props.featureImg;
    const screenshot = props.screenshotImg;
    const images = [
      {
        src: features[locale].font,
        caption: featureText['features:font'],
      },
      {
        src: features[locale].md,
        caption: featureText['features:md'],
      },
      {
        src: features[locale].background,
        caption: featureText['features:highlight'],
      },
      {
        src: features[locale].image,
        caption: featureText['features:image'],
      },
      {
        src: features[locale].code,
        caption: featureText['features:code'],
      },
      {
        src: features[locale].table,
        caption: featureText['features:table'],
      },
      {
        src: features[locale].url,
        caption: featureText['features:url'],
      },
      {
        src: screenshot[system].preview1,
        caption: featureText['features:screen1'],
      },
      {
        src: screenshot[system].preview2,
        caption: featureText['features:screen2'],
      },
    ];
    const lightboxImages = images.map(item => (
      {
        ...item,
        src: item.src.childImageSharp.fluid.src,
        srcSet: item.src.childImageSharp.fluid.srcSet,
      }
    ));
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      showVideo: false,
      images,
      lightboxImages,
    };
    this.imageLength = images.length;
  }

  showLightbox = (id) => {
    this.setState({ currentImage: id, lightboxIsOpen: true });
  }

  gotoPrevious = () => {
    this._control(-1);
  }

  gotoNext = () => {
    this._control(1);
  }

  _control = (direction) => {
    const { currentImage } = this.state;
    let next = currentImage + direction;

    if (next === this.imageLength) {
      next = 0;
    } else if (next < 0) {
      next = this.imageLength - 1;
    }
    this.setState({ currentImage: next });
  }

  toggleVideo = (showVideo) => {
    this.setState({ showVideo });
  }

  closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
  }

  render() {
    const { homeImg, locale } = this.props;
    const {
      lightboxIsOpen, currentImage, images, lightboxImages, showVideo,
    } = this.state;

    return (
      <div>
        <section className="hero is-bold hero-customized">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-6">
                  <div className="home-hero-left">
                    <div className="mb3">
                      {/* <img className="home-hero-logo" src={logo} alt="logo" /> */}
                      <div className="home-logo-word">
                        <h1 className="product-name mb3">
                          <FormattedMessage id="looptext.use" />
                          {' '}
                          {' '}
                          <TextLoop interval={2300}>
                            <span className="loop-text">Markdown</span>
                            <span className="loop-text"><FormattedMessage id="looptext.code" /></span>
                            <span className="loop-text"><FormattedMessage id="looptext.excel" /></span>
                            <span className="loop-text"><FormattedMessage id="looptext.style" /></span>
                          </TextLoop>
                          {locale === 'en'
                            ? (
                              <>
                                {' '}
                                in Evernote
                              </>
                            )
                            : null}
                        </h1>
                        <h2 className="product-sub mb2">
                          <FormattedMessage id="subtitle.line1" />
                          {' '}
                          <br />
                          <FormattedMessage id="subtitle.line2" />
                          {' '}
                          <br />
                        </h2>
                      </div>
                    </div>
                    <ul className="feature-list">
                      <li>
                        <span className="mr1" role="img" aria-label="hash">#️⃣</span>
                        <FormattedMessage id="bullet2" />
                      </li>
                      <li>
                        <span className="mr1" role="img" aria-label="point">☝</span>
                        <FormattedMessage id="bullet1" />
                      </li>
                      <li>
                        <span className="mr1" role="img" aria-label="pan">🖍</span>
                        <FormattedMessage id="bullet3" />
                      </li>
                      <li>
                        <span className="mr1" role="img" aria-label="pepper">📃</span>
                        <FormattedMessage id="bullet4" />
                      </li>
                      <li>
                        <span className="mr1" role="img" aria-label="link">🔗</span>
                        <FormattedMessage id="bullet5" />
                      </li>
                    </ul>
                    <DownloadBadge />
                    <button type="button" className="button is-dark play-button" onClick={() => this.toggleVideo(true)}>
                      <i className="far fa-play-circle" />
                      <FormattedMessage id="demo.video" />
                    </button>
                  </div>
                </div>
                <div className="column is-6">
                  <Img fluid={homeImg.childImageSharp.fluid} className="home-hero-right-img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="content center">
          <TrustedUser />
          <div className="mb5 mt5">
            <div className="container">
              <h3><FormattedMessage id="features:title" /></h3>
              <div className="columns">
                <Feature
                  img={images[0].src}
                  text={images[0].caption}
                  onClick={() => this.showLightbox(0)}
                />
                <Feature
                  img={images[1].src}
                  text={images[1].caption}
                  onClick={() => this.showLightbox(1)}
                />
                <Feature
                  img={images[2].src}
                  text={images[2].caption}
                  onClick={() => this.showLightbox(2)}
                />
              </div>
              <div className="columns">
                <Feature
                  img={images[3].src}
                  text={images[3].caption}
                  onClick={() => this.showLightbox(3)}
                />
                <Feature
                  img={images[4].src}
                  text={images[4].caption}
                  onClick={() => this.showLightbox(4)}
                />
                <Feature
                  img={images[5].src}
                  text={images[5].caption}
                  onClick={() => this.showLightbox(5)}
                />
              </div>
              <div className="columns">
                <Feature
                  img={images[6].src}
                  text={images[6].caption}
                  onClick={() => this.showLightbox(6)}
                />
                <Feature
                  img={images[7].src}
                  text={images[7].caption}
                  onClick={() => this.showLightbox(7)}
                />
                <Feature
                  img={images[8].src}
                  text={images[8].caption}
                  onClick={() => this.showLightbox(8)}
                />
              </div>
            </div>
          </div>
          <DemoList />
          <div className="container pt5">
            <div className="pb5">
              <TwitterFans />
            </div>
            <hr />
            <div className="pt2">
              <h2><FormattedMessage id="cta:title" /></h2>
              <p><FormattedMessage id="cta:subtitle" /></p>
              <h4><FormattedMessage id="ready.productivity" /></h4>
              <DownloadBadge />
            </div>
          </div>
        </div>
        <Lightbox
          images={lightboxImages}
          isOpen={lightboxIsOpen}
          currentImage={currentImage}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClose={this.closeLightbox}
        />
        <Modal
          open={showVideo}
          onClose={() => this.toggleVideo(false)}
          center
          styles={{ modal: { height: '460px', width: '80%', textAlign: 'center' } }}
        >
          {fromChina
            ? <Video link="/video/intro" />
            : (
              <div className="demo-video">
                <iframe
                  title="EverTool demo"
                  width="100%"
                  height="394"
                  src="https://www.youtube.com/embed/vZSFpk3PUGE"
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              </div>
            )
          }
        </Modal>
      </div>
    );
  }
}

export default Index;
